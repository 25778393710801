"use client";

import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  getTypes,
  PRODUCT_LIST,
  S3_BASE_URL,
  USA_REGION_ID,
} from "@/utils/constants";
import SliderModal from "./sliderModal";
import CartModal from "../popUpSection/cartModel";
import ViewModal from "../popUpSection/viewModel";
import {
  addProductToCart,
  clearCart,
  createCart,
  fetchCart,
  fetchCheckoutData,
  fetchProducts,
  updateCartLineQuantity,
} from "@/utils/Shopify/products";
import { useCart } from "@/context/CartContext";
import HsaPrequalify from "../HsaFsaWidget";
import { BannerSlider, ProductContent } from "@/types/product-content";
import FrontrowWidget from "../frontRowWidget";
import axios from "@/utils/axios";
import StaticBottomAd from "./staticBottomAd";
import TopNavBar from "./topNavbar";
import { ProductVariantMapping } from "@/types/shopify-product";

interface BannerProps {
  product: ProductContent;
  slug: string;
}

interface ArrowProps {
  onClick: () => void;
  index: number;
}

let reviewProductId: any;
export default function ProductBanner({ product, slug }: any) {
  if (slug === "mark") {
    reviewProductId = 6878347133027;
  } else if (slug === "range") {
    reviewProductId = 6633840476259;
  } else if (slug === "orbit") {
    reviewProductId = 4167630651491;
  } else if (slug === "band") {
    reviewProductId = 6546274156643;
  } else if (slug === "book") {
    reviewProductId = 4167455015011;
  } else if (slug === "set") {
    reviewProductId = 7009601257571;
  }
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn1.stamped.io/files/widget.min.js";
    script.type = "text/javascript";
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      setTimeout(() => {
        window.StampedFn.init({
          apiKey: "pubkey-7Y2q27EZx658143R5FRdYjA1FiKK4I",
          sId: "150068",
        });

        window.StampedFn.reloadUGC();
      }, 1500);
    };

    // return () => {
    //   // Cleanup the script when the component unmounts
    //   if (script && script.parentNode) {
    //     script.parentNode.removeChild(script);
    //   }
    // };
  }, [reviewProductId]);
  const PrevArrow: React.FC<ArrowProps> = ({ onClick, index }) => {
    const isDisabled = index === 0;
    const backgroundColor = isDisabled
      ? "transparent"
      : selectedSlide > 0
      ? "white"
      : "transparent";
    const color = isDisabled ? "white" : selectedSlide > 0 ? "black" : "white";
    return (
      <div
        onClick={onClick}
        className="product-prev product-arrow arrow-button"
        style={{ backgroundColor, pointerEvents: isDisabled ? "none" : "auto" }}
      >
        <span style={{ color }}>
          <Image
            src="./assets/images/arrow-white.svg"
            width={20}
            height={20}
            alt=""
            className={`arrow-image d-flex justify-content-center align-items-center ${
              index === selectedSlide ? "selected" : ""
            }`}
            style={{ filter: `invert(${color === "black" ? 1 : 0})` }}
          />
        </span>
      </div>
    );
  };

  const NextArrow: React.FC<ArrowProps> = ({ onClick, index }) => {
    const isDisabled = index === product.banner_slider.length - 1;
    const color = isDisabled
      ? "white"
      : selectedSlide < product.banner_slider.length - 1
      ? "black"
      : "white";
    const backgroundColor = isDisabled
      ? "transparent"
      : selectedSlide < product.banner_slider.length - 1
      ? "white"
      : "transparent";
    return (
      <div
        onClick={onClick}
        className="product-next product-arrow arrow-button"
        style={{ backgroundColor, pointerEvents: isDisabled ? "none" : "auto" }}
      >
        <span style={{ color }}>
          <Image
            src="./assets/images/arrow-white-right.svg"
            width={20}
            height={20}
            alt=""
            className={`arrow-image d-flex justify-content-center align-items-center ${
              index === selectedSlide ? "selected" : ""
            }`}
            style={{ filter: `invert(${color === "black" ? 1 : 0})` }}
          />
        </span>
      </div>
    );
  };

  const [selectedSlide, setSelectedSlide] = useState(0);
  const nestedSliderRef = useRef<Slider>(null);
  const cardSliderRef = useRef<Slider>(null);
  const sliderRef = useRef<Slider>(null);
  const imageSliderRef = useRef<Slider>(null);

  const handleAfterChange = (currentSlide: number) => {
    setSelectedSlide(currentSlide);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [clickedItem, setClickedItem] = useState<any>(null);
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const [cartShowModel, setCartShowModel] = useState<boolean>(false);
  const [sampleShowModel, setSampleShowModel] = useState<boolean>(false);

  const [shopifyProducts, setShopifyProducts] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [caption, setCaption] = useState<any>();
  const [colorTitle, setColorTitle] = useState<any>("Limited Edition Black");
  const [selectedColor, setSelectedColor] = useState<any>("");

  const { itemCount, setItemCount } = useCart();
  const { cart, setCart } = useCart();

  const cardSliderSettings = {
    dots: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 5000,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const goToNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handleShow = (item: BannerSlider, index: number) => {
    setClickedItem(item);
    setClickedIndex(index);
    setShowModal(true);
  };
  const handleCartShow = () => {
    setCartShowModel(true);
  };
  const handleSampleShow = () => {
    setSampleShowModel(true);
  };
  const handleClose = () => {
    setShowModal(false);
    setClickedItem(null);
    setClickedIndex(null);
  };

  const handleCartClose = () => {
    setCartShowModel(false);
  };

  const handleSampleClose = () => {
    setSampleShowModel(false);
  };

  const handleSlideChange = (index: number) => {
    sliderRef.current?.slickGoTo(index);
    nestedSliderRef.current?.slickGoTo(index);
    cardSliderRef.current?.slickGoTo(index);
    imageSliderRef.current?.slickGoTo(index);
    setSelectedSlide(index);
  };

  useEffect(() => {
    cardSliderRef.current?.slickGoTo(selectedSlide);
    imageSliderRef.current?.slickGoTo(selectedSlide);
  }, [selectedSlide]);

  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        const data = await fetchProducts();
        setShopifyProducts(data);
        data.forEach((shopifynode: any) => {
          if (shopifynode.node.id.includes(product.productId)) {
            product.video_banner_title = shopifynode.node.title;
            shopifynode.node.variants.edges.forEach((edge: any) => {
              if (product.variantIds.length > 1) {
                if (edge.node.id.includes(product.variantIds[1])) {
                  product.shopifyPrice = "$" + Number(edge.node.priceV2.amount);
                  product.compareAtPrice =
                    "$" + Number(edge.node.compareAtPriceV2.amount);
                }
              } else if (product.variantIds.length === 1) {
                if (edge.node.id.includes(product.variantIds[0])) {
                  product.shopifyPrice = "$" + Number(edge.node.priceV2.amount);
                  product.compareAtPrice =
                    "$" + Number(edge.node.compareAtPriceV2.amount);
                }
              }
            });
          }
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError("Failed to fetch products");
      }
    };
    loadProducts();
    let storedData = localStorage.getItem("cartCheckoutData");
    let checkoutId = storedData ? JSON.parse(storedData).checkoutid : null;
    if (checkoutId) {
      const checkout = fetchCheckoutData(checkoutId);
      checkout.then((data) => {
        if (data.completedAt) {
          let storedData = localStorage.getItem("cartCheckoutData");
          let cartId = storedData ? JSON.parse(storedData).cartId : null;
          if (cartId) {
            try {
              const clearedCart = clearCart(cartId);
              setCart(clearedCart);
              setItemCount(0);
              localStorage.removeItem("cartCheckoutData");
            } catch (err) {
              setError("Failed to clear cart.");
            }
          }
          return true;
        } else {
          return false;
        }
      });
    }

    let storedCartData = localStorage.getItem("cartCheckoutData");
    let cartId = storedCartData ? JSON.parse(storedCartData).cartId : null;
    if (cartId) {
      const updatedCart = fetchCart(cartId);
    }
  }, []);

  const [productList, setProductList] = useState<any[]>([]);
  const [productPrice, setProductPrice] = useState<any>();
  const [compareAtPrice, setCompareAtPrice] = useState<any>();
  const [saveValue, setSaveValue] = useState<any>();

  const [bookType, setBookType] = useState<any>();
  const [productIndex, setProductIndex] = useState<any>(0);

  let variantId = "";

  const fetchProductsList = async () => {
    try {
      const response: any = await axios.get(
        `${PRODUCT_LIST}?slug=${slug}&region_id=${USA_REGION_ID}`
      );
      setProductList(response?.data?.data);
    } catch (error) {
      console.log("Error in fetching connect us Data ");
    }
  };

  useEffect(() => {
    fetchProductsList();
  }, []);

  const productStockList: ProductVariantMapping[] = [];

  productList.forEach(
    (product: {
      shopify_product_id: string;
      shopify_variant_id: any;
      name: string;
      variant_sub_title: string;
      color: any;
    }) => {
      const productId = product.shopify_product_id; // The part before the first hyphen
      const variantId = product.shopify_variant_id; // Use the full variant ID
      const title = product.name;
      const subtitle = product.variant_sub_title;
      const color = product.color;

      const existingProduct = productStockList.find(
        (mapping) => mapping.productId === productId
      );

      if (!existingProduct) {
        productStockList.push({
          productId: productId,
          variantId: [variantId],
          title: title,
          variant_sub_title: subtitle,
          color: color,
        });
      } else {
        existingProduct.variantId.push(variantId);
      }
    }
  );

  const initializeProductPrice = (variantId: any, productId: any) => {
    shopifyProducts.forEach((shopifynode: any) => {
      if (shopifynode.node.id.includes(productId)) {
        shopifynode.node.variants.edges.forEach((edge: any) => {
          if (edge.node.id.includes(variantId)) {
            setProductPrice(edge.node.priceV2.amount); // Set product price
            setCompareAtPrice(edge.node.compareAtPriceV2?.amount); // Set compare-at price
          }
        });
      }
    });
  };

  const showCaption = (slug: any) => {
    if (slug === "set") {
      return "New + Limited Edition Black Set";
    } else if (slug === "mark") {
      return "New + Limited Edition Black Mark";
    } else if (slug === "range") {
      return "New + Limited Edition Black Range";
    }
  };
  const [selectedvariant, setSelectedVariant] = useState("");

  useEffect(() => {
    if (productList.length > 0) {
      const defaultVariant = productList[0];
      setSelectedVariant(defaultVariant?.shopify_variant_id);
      initializeProductPrice(
        defaultVariant?.shopify_variant_id,
        defaultVariant?.shopify_product_id
      );
    }
  }, [productList]);

  const handleType = (item: any, index: number) => {
    setSelectedVariant(item?.shopify_variant_id);
    setBookType(item.book_type);
    setProductPrice(item?.prices?.final?.price);
    setProductIndex(index);
  };
  const handleVariant = (item: any, index: number) => {
    setSelectedVariant(item?.shopify_variant_id);
    shopifyProducts.forEach((shopifynode: any) => {
      if (shopifynode.node.id.includes(item.shopify_product_id)) {
        shopifynode.node.variants.edges.forEach((edge: any) => {
          if (edge.node.id.includes(item.shopify_variant_id)) {
            setProductPrice(edge.node.priceV2.amount);
            setCompareAtPrice(edge.node.compareAtPriceV2?.amount);
          }
        });
      }
    });
    if (item.color === 5) {
      setColorTitle("White");
      setSaveValue("Save 25%");
    } else if (item.color === 4) {
      setColorTitle("Limited Edition Black");
      setSaveValue("Save 40%");
    }

    setProductIndex(index);
  };

  const handleAddToCart = async (productStockList: ProductVariantMapping[]) => {
    console.log(productStockList, "productStockList");
    // product and variant added
    const productId = productStockList[0]?.productId;
    let variantId = "";

    if (selectedvariant === null) {
      variantId = productStockList[0]?.variantId[0];
    } else {
      variantId = selectedvariant;
    }

    let storedData = localStorage.getItem("cartCheckoutData");
    let cartId = storedData ? JSON.parse(storedData).cartId : null;

    if (cartId) {
      // Fetch the existing cart data
      const cartData = await fetchCart(cartId);

      // Check if the product already exists in the cart
      let existingLineItem;
      if (variantId) {
        // If there's a variant, check if the variant already exists in the cart
        existingLineItem = cartData?.lines?.edges.find(
          (edge: any) => edge.node.merchandise.id === variantId
        );
      } else {
        // If no variant, find the product by productId
        existingLineItem = cartData?.lines?.edges.find(
          (edge: any) => edge.node.merchandise.product.id === productId
        );
      }

      let updatedCart;

      if (existingLineItem) {
        // If the product (variant or simple product) already exists in the cart, update the quantity
        updatedCart = await updateCartLineQuantity(
          cartId,
          existingLineItem.node.id,
          existingLineItem.node.quantity + 1
        );
        setCart(updatedCart);
      } else {
        // If the product (variant or simple product) is new, add it to the cart

        if (variantId) {
          // If there's a variant, add the product with variant
          updatedCart = await addProductToCart(cartId, variantId, 1);
        } else {
          // If it's a simple product, add it by productId
          updatedCart = await addProductToCart(cartId, productId, 1);
        }
        setCart(updatedCart);
      }
      // Recalculate and update the total item count based on the updated cart
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);

      // Update local storage with current cartId and checkoutId (if any)
      storedData = localStorage.getItem("cartCheckoutData");
      const { checkoutId } = storedData
        ? JSON.parse(storedData)
        : { checkoutId: null };
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId, checkoutId })
      );
    } else {
      // If no cart ID exists, create a new cart
      const newCartId = await createCart();
      const newCheckoutId = null;
      localStorage.setItem(
        "cartCheckoutData",
        JSON.stringify({ cartId: newCartId, checkoutId: newCheckoutId })
      );

      // Fetch the newly created cart
      const cartData = await fetchCart(newCartId);
      setCart(cartData);

      // Add the product to the newly created cart
      let updatedCart;
      if (variantId) {
        // If there's a variant, add the product with variant
        updatedCart = await addProductToCart(newCartId, variantId, 1);
      } else {
        // If it's a simple product, add it by productId
        updatedCart = await addProductToCart(newCartId, productId, 1);
      }
      setCart(updatedCart);

      // Recalculate and update the item count
      const newItemCount = updatedCart.lines.edges.reduce(
        (total: any, edge: any) => total + edge.node.quantity,
        0
      );
      setItemCount(newItemCount);
    }
  };

  const [showAd, setShowAd] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const isSmallScreen = window.innerWidth <= 768; // Adjust for your breakpoint
      if (isSmallScreen && window.scrollY > 300) {
        // Change 300 to the desired scroll pixel value
        setShowAd(true);
      } else {
        setShowAd(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fireProductDetailViewEvent = (variant: any, slug: any) => {
    const filteredProduct = productList.find(
      (product) => product.shopify_variant_id === selectedvariant
    );

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ProductDetailView",
      products: {
        item_id: product.productId,
        variantId: selectedvariant,
        item_name: product.video_banner_title,
        price: filteredProduct?.prices?.final?.price,
        description: product.video_banner_description,
        imageURL: product.tutorial_desktop_image_url,
        comparePrice: filteredProduct?.prices?.regular?.price,
      },
    });
  };

  useEffect(() => {
    fireProductDetailViewEvent(selectedvariant, slug);
  }, [slug, selectedvariant]);

  return (
    <>
      <SliderModal
        show={showModal}
        handleClose={handleClose}
        product={clickedItem}
        index={clickedIndex}
      />

      <CartModal cartShow={cartShowModel} handleClose={handleCartClose} />

      <ViewModal
        product={product}
        slug={slug}
        selectedvariant={selectedvariant}
        productPrice={productPrice}
        show={sampleShowModel}
        handleClose={handleSampleClose}
      />
      <div className="banner-wrapper container">
        <div className="row">
          <div className="col-12 col-lg-6 img-container">
            <div className="position-relative">
              <Slider
                {...cardSliderSettings}
                ref={sliderRef}
                className="p-0 m-0 gap-4"
              >
                {product?.banner_slider.map(
                  (item: BannerSlider, index: number) => (
                    <div key={index} className="position-relative">
                      <Image
                        src={`${S3_BASE_URL}${item?.image_url}`}
                        alt="img"
                        className="card-img-top img-fluid p-1"
                        width={600}
                        height={625}
                      />
                      <div className="position-absolute top-0">
                        {item?.seller_flag && (
                          <button className="best-seller-btn">
                            {item?.seller_flag || ""}
                          </button>
                        )}
                      </div>
                      {item?.video_url && (
                        <div
                          className="play-section d-flex flex-row align-items-center px-3 position-absolute bottom-0 py-2 my-4 ms-3"
                          onClick={() => handleShow(item, index)}
                        >
                          <div className="vedio-play-icon me-1"></div>
                          <p className="mb-0">View in Use</p>
                        </div>
                      )}
                    </div>
                  )
                )}
              </Slider>

              <div className="position-absolute bottom-0 d-flex flex-row end-0 py-2 my-4 me-4">
                <div>
                  <div className="d-none d-sm-block product-arrows-container">
                    <div className="row gap-3">
                      <div className="col">
                        <PrevArrow onClick={goToPrev} index={selectedSlide} />
                      </div>
                      <div className="col">
                        <NextArrow onClick={goToNext} index={selectedSlide} />
                      </div>
                    </div>
                  </div>
                  <div className="d-block d-sm-none">
                    {product.banner_slider.map(
                      (_: BannerSlider, index: any) => (
                        <div
                          key={index}
                          className="form-check form-check-inline p-1"
                        >
                          <input
                            className="form-check-input radio-button-slider"
                            type="radio"
                            id={`radio-${index}`}
                            name="slider-control"
                            checked={selectedSlide === index}
                            onChange={() => handleSlideChange(index)}
                            style={{
                              backgroundColor:
                                selectedSlide === index
                                  ? "rgba(248, 247, 244, 1)"
                                  : "rgba(248, 247, 244, 0.5)",
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6  d-flex align-items-center p-sm p-0">
            <div className="product-banner-detail d-flex flex-column">
              <div className="order-1 ">
                {slug === "mark" || slug === "range" || slug === "set" ? (
                  <div>
                    <p
                      style={{
                        color: "#E73D51",
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      {caption ? caption : showCaption(slug)}
                    </p>
                  </div>
                ) : (
                  <p></p>
                )}
                <div className="w-100 d-flex flex-row align-items-end justify-content-between mb-2">
                  <p className="text-white product-name mb-0">
                    {product?.video_banner_title || ""}
                  </p>
                </div>
                <p
                  className=" banner-product-description  mb-0"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "20px",
                    fontWeight: "274",
                    paddingBottom: "8px",
                    paddingTop: "8px",
                  }}
                >
                  {product?.banner_faq_title || ""}
                </p>
                <p
                  className="mb-1 product-sub-title"
                  style={{
                    color: "#FFFFFF",
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontWeight: "590",
                  }}
                >
                  {product?.video_banner_description || ""}
                </p>
                <span
                  className="stamped-product-reviews-badge stamped-main-badge ms-3 mb-0 mt-3"
                  data-id={reviewProductId}
                ></span>

                <div className="my-3">
                  {slug === "mark" || slug === "range" || slug === "set" ? (
                    <FrontrowWidget />
                  ) : (
                    <p></p>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "32px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    borderTop: "0.5px solid #848484",
                    borderBottom: "0.5px solid #848484",
                  }}
                >
                  <p
                    className="product-name"
                    style={{
                      color: "#FFFFFF",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "500",
                      letterSpacing: "1.1px",
                      margin: 0,
                    }}
                  >
                    Price:
                  </p>
                  <div>
                    <div>
                      <div
                        className="w-100 d-flex flex-row mb-2 gap-2"
                        style={{ flexWrap: "nowrap", alignItems: "center" }}
                      >
                        <span className="mb-0 text-bottom current-price">
                          {slug === "set" && <span>Now </span>}
                          {productPrice
                            ? "$" + `${Number(productPrice)}`
                            : product?.shopifyPrice || " "}
                        </span>
                        <span className="mb-0 text-bottom compareat-price">
                          {bookType !== 11 &&
                            (compareAtPrice
                              ? "$" + `${Number(compareAtPrice)}`
                              : product.compareAtPrice || " ")}
                        </span>
                        {slug === "set" && (
                          <span
                            style={{
                              background: "#E73D51",
                              borderRadius: "15px",
                              padding: "2px 6px",
                              marginLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span className="saveValue">
                              {saveValue ? saveValue : "Save 40%"}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>

                    <HsaPrequalify />
                  </div>
                </div>
              </div>

              <div className="order-2 order-sm-2 mt-0">
                <div
                  style={{
                    paddingTop: "16px",
                    paddingBottom: "16px",
                  }}
                >
                  {slug === "mark" || slug === "range" || slug === "set" ? (
                    <div style={{ display: "flex", gap: "32px" }}>
                      <p
                        className="product-name mb-0"
                        style={{
                          color: "#FFFFFF",
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontWeight: "500",
                          letterSpacing: "1.1px",
                        }}
                      >
                        Color:{" "}
                      </p>
                      <div>
                        <span style={{ paddingLeft: "8px", color: "#FFFFFF" }}>
                          {colorTitle}
                        </span>
                        <div
                          className="d-flex gap-3"
                          style={{ paddingTop: "10px" }}
                        >
                          {productList?.length > 1 && (
                            <>
                              {productList
                                ?.sort((a, b) => {
                                  if (a.color === 4 && b.color !== 4) return -1;
                                  if (a.color !== 4 && b.color === 4) return 1;
                                  return 0;
                                })
                                .map((item: any, index: number) => (
                                  <div className="mb-2 mt-0" key={index}>
                                    {item?.color && (
                                      <div
                                        style={{
                                          display: "inline-flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "48px",
                                          height: "48px",
                                          borderRadius: "50%",
                                          border:
                                            productIndex === index
                                              ? "2px solid #0171DE"
                                              : "none",
                                          transition: "border 0.3s ease",
                                        }}
                                      >
                                        <button
                                          style={{
                                            borderRadius: "50%",
                                            width: "40px",
                                            height: "40px",
                                            backgroundColor: getTypes(
                                              item?.color
                                            ),
                                            border: "1px solid #848484",
                                          }}
                                          type="button"
                                          className="btn paperback-btn text-white w-40 h-40 p-2"
                                          onClick={() => {
                                            setSelectedColor(item.color);
                                            handleVariant(item, index);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}

                  {slug === "book" && productList?.length > 1 && (
                    <>
                      {productList?.map((item: any, index: number) => (
                        <div className="mb-2 mt-0 mt-sm-2" key={index}>
                          {item?.book_type && (
                            <button
                              type="button"
                              className={`btn paperback-btn text-white w-100 p-2 ${
                                productIndex === index ? "active" : ""
                              }`}
                              onClick={() => handleType(item, index)}
                            >
                              {getTypes(item?.book_type)}
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <button
                  type="button"
                  className="btn text-white w-100  add-to-cart-btn"
                  onClick={() => {
                    handleCartShow();
                    handleAddToCart(productStockList);
                    // handleAddToCart(product.productId, product.variantIds);
                  }}
                >
                  Add to cart
                </button>

                <div className="text-center mt-3 w-100">
                  <div
                    onClick={handleSampleShow}
                    className="text-white view-sample text-decoration-none bg-transparent border-none"
                    style={{ cursor: "pointer" }} // Add cursor style for better UX
                  >
                    view sample plans
                  </div>
                </div>
              </div>
              <div className="order-3 order-sm-3  pt-5">
                <div className="faq-section d-flex">
                  <div className="w-100">
                    {product.banner_accordion?.map(
                      (item: any, index: number) => (
                        <div
                          className="accordion bg-transparent"
                          id={`accordionExample ${index}`}
                          key={index}
                        >
                          <div className="accordion-item bg-transparent border-0">
                            <h3 className="accordion-header border-top">
                              <button
                                className={`accordion-button  product-accordion-text px-0 p-2 ${
                                  activeIndex === index ? "" : "collapsed"
                                } bg-transparent text-white`}
                                type="button"
                                onClick={() => toggleAccordion(index)}
                              >
                                {item?.title}
                              </button>
                            </h3>
                            <div
                              className={`accordion-collapse  ${
                                activeIndex === index ? "show" : ""
                              }`}
                              id={`collapse${index}`}
                            >
                              <div className="accordion-body bg-transparent text-white px-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.description ?? "",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* {(slug !== 'book') &&
             <div className="row" style={{marginTop:'20px'}}>
                   <div className="col-4">
                     <div className="d-flex h-100 flex-column justify-content-between align-items-center">
                       <img
                         alt="Description of the image"
                         loading="lazy"
                         width="40"
                         height="47"
                         decoding="async"
                         data-nimg="1"
                         className="img-fluid mb-2 text-white"
                         style={{ color: "transparent" }}
                         src="/_next/image?url=%2Fassets%2Fimages%2Fnew-image%2Fset%2Frepeat-60.png&amp;w=96&amp;q=75"
                       ></img>
                       <p className="text-center mb-0 text-white">
                         60 day money back guarantee
                       </p>
                     </div>
                   </div>
                   <div className="col-4">
                     <div className="d-flex h-100 flex-column justify-content-between align-items-center">
                       <img
                         alt="Description of the image"
                         loading="lazy"
                         width="75"
                         height="31"
                         decoding="async"
                         data-nimg="1"
                         className="img-fluid text-white"
                         style={{ color: "transparent" }}
                         src="/_next/image?url=%2Fassets%2Fimages%2Fnew-image%2Fset%2Ffree-shipping.png&amp;w=256&amp;q=75"
                       ></img>
                       <p className="text-center mb-0 text-white">
                         Free Shipping on orders of $75+
                       </p>
                     </div>
                   </div>
                   <div className="col-4">
                     <div className="d-flex h-100 flex-column justify-content-between align-items-center text-white">
                       <img
                         alt="Description of the image"
                         loading="lazy"
                         width="83"
                         height="18"
                         decoding="async"
                         data-nimg="1"
                         className="img-fluid text-white"
                         style={{ color: "transparent" }}
                         src="/_next/image?url=%2Fassets%2Fimages%2Fnew-image%2Fset%2Fshop-pay.png&amp;w=256&amp;q=75"
                       ></img>
                       <p className="text-center m-0 text-white">
                         Shop Pay <br /> Available
                       </p>
                     </div>
                   </div>
                 </div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-block d-none">
        <TopNavBar
          selectedvariant={selectedvariant}
          productList={productList}
          product={product}
          productPrice={productPrice}
          slug={slug}
          productIndex={productIndex}
        />
      </div>

      {showAd && (
        <StaticBottomAd
          selectedvariant={selectedvariant}
          productList={productList}
          product={product}
          productPrice={productPrice}
          slug={slug}
          productIndex={productIndex}
        />
      )}
    </>
  );
}
