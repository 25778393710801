import { useEffect } from "react";

const HsaPrequalify: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/prequal-widget.min.js";
    script.defer = true;

    document.body.appendChild(script);

    // return () => {
    //   if (script && script.parentNode) {
    //     script.parentNode.removeChild(script);
    //   }
    // };
  }, []);

  const handleButtonClick = () => {
    const element = document.querySelector(
      ".truemed-instructions-open"
    ) as HTMLElement;
    element?.click();
  };

  return (
    <>
      <div className="truemed-widget-container">
        <div
          id="truemed-prequalify"
          style={{ fontSize: "14px" }}
          icon-height="12"
          data-public-id="tm_qual_sppo8q9g6k"
        ></div>
      </div>

      <button onClick={handleButtonClick}>
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ verticalAlign: "middle", marginRight: "8px" }}
        >
          <path
            d="M5 12.8516L11.5 19.8516L26.5 0.851562"
            stroke="#F8F7F4"
            stroke-width="2"
          />
        </svg>
        <span className="hsa-fsa">HSA/FSA</span>
        <span className="accepted">accepted</span>
        <img
          className="truemed-logo"
          src="https://truemed-public.s3.us-west-1.amazonaws.com/truemed-ads/truemed-white.svg"
        />
      </button>

      <style jsx>{`
        .truemed-widget-container {
          display: none !important;
          background-color: #0c1218;
          color: #f8f7f4;
          padding: 10px;
          border-radius: 8px;
          font-family: "SF Pro", sans-serif; /* Set font to SF Pro */
          display: flex;
          padding: 0; /* Remove extra padding */
          height: 32px; /* Set the height of the widget */
        }
        /* Style the button */
        button {
          background-color: #0c1218;
          color: #f8f7f4;
          padding: 0 px; /* Adjust padding to fit height */
          font-size: 14px;
          font-family: "SF Pro", sans-serif;
          border: none; /* Removed border */
          border-radius: 5px;
          cursor: pointer; /* Keeps the pointer cursor on hover */
          height: 32px; /* Set the button height to match the widget height */
          align-items: center; /* Align items vertically */
          justify-content: center; /* Center content horizontally */
        }
        /* Remove hover effect from the button (no color change on hover) */
        button:hover {
          background-color: #0c1218;
          color: #f8f7f4;
        }
        /* Adjust image in the button */
        img {
          vertical-align: middle;
          margin-left: 8px;
          height: 12px;
        }
        /* Add some extra styling for the SVG inside the button */
        svg {
          vertical-align: middle;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }

        .hsa-fsa {
          font-weight: bold; /* HSA/FSA in bold */
        }
        .accepted {
          font-weight: 300;
          padding-left: 7px;
        }
      `}</style>
    </>
  );
};

export default HsaPrequalify;
